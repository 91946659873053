<template>
  <div class="course-list-page">
   <Header/>
    <!-- DONE:面包屑导航 -->
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{
          typeName && !$route.query.type
            ? typeName
            : !typeName && $route.query.type
            ? "推荐课程"
            : "考试与练习"
        }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="list-box" v-if="loading">
      <div class="list-item" v-for="course in courseList" :key="course.id">
        <div class="list-item-content">
          <div class="content-img" @click="kindTo(course.id, course.title)">
            <img :src="course.pic" />
            <div class="ellipsis">
              <span class="content-text">{{ course.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-empty
        v-if="empty"
        :image="
          require(`../assets/images/${
            $route.query.tid ? 'no_course' : 'no_paper'
          }.png`)
        "
        :description="`暂无${$route.query.tid ? '课程' : '试卷'}`"
      ></el-empty>
      <el-pagination
        v-if="pageEmpty && totalNumber > 15"
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChangeList"
      >
      </el-pagination>
      <el-pagination
        v-if="getCourseAllShow && totalNumber > 15"
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChangeAll"
      >
      </el-pagination>
      <el-pagination
        v-if="recommendPage && totalNumber > 15"
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="changeRecommendPage"
      >
      </el-pagination>
    </div>
    <Foot/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Foot from "../components/Foot.vue";
export default {
  data() {
    return {
      typeName: "",
      totalNumber: 1,
      pageSize: 1,
      courseList: [],
      currentPage: 1, //默认
      getCourseListShow: false, //有id的课程列表
      getCourseAllShow: false, //全部课程列表
      empty: false,
      loading: false,
      pageEmpty: false,
      recommendPage: false,
    };
  },

  components: { Header,Foot},

  computed: {},
  mounted() {
    this.getPower();
    if (this.$route.query.tid) {
      this.getCourseList();
      this.getCourseListShow = true; //有id的课程列表
    } else if (this.$route.query.type) {
      this.moreRecommend();
    } else {
      this.getAllCourse();
      this.getCourseAllShow = true; //全部课程列表
    }
  },
  methods: {
    //获取有id的列表
    getCourseList() {
      this.typeName = this.$route.query.tn;
      let fd = new FormData();
      fd.append("type_id", this.$route.query.tid);
      fd.append("page_size", 15);
      this.axios.post("/index/course_list", fd).then((res) => {
        console.log(res);
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
        this.loading = true;
        if (this.totalNumber == 0) {
          this.empty = true;
        } else {
          this.pageEmpty = true;
        }
      });
    },
    // 调用判断是否有看这个课程的权限
    getPower(){
       this.axios.get("index/user_free_status").then((res)=>{
          // console.log(res);
          
       })
       },
    
    //获取全部课程列表
    getAllCourse() {
      let fd = new FormData();
      fd.append("page_size", 15);
      this.axios.post("/index/course_list", fd).then((res) => {
        console.log(res);
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
        this.loading = true;
        if (this.totalNumber == 0) {
          this.empty = true;
        }
      });
    },
    // 各种跳转
    kindTo(courseId, ct) {
      if (!this.$route.query.tid && !this.$route.query.type) {
        this.$router.push(`/exercise?cid=${courseId}`);
      } else {
        this.$router.push(
          `/courdetails?tid=${this.$route.query.tid}&cid=${courseId}&ct=${ct}&tn=${this.$route.query.tn}`
        );
      }
    },
    //更改页码有id
    handleCurrentChangeList(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 15);

      this.axios.post("/index/course_list", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);

        this.courseList = res.data.list;
      });
    },
    //更改页码全部
    handleCurrentChangeAll(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 15);
      this.axios.post("/index/course_list", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
      });
    },
    // 更多推荐课程列表
    moreRecommend() {
      let fd = new FormData();
      fd.append("page_size", 15);
      this.axios.post("/index/more_course_recommend", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
        this.loading = true;
        if (res.data.count == 0) {
          this.empty = true;
          this.recommendPage = false;
        } else {
          this.recommendPage = true;
        }
      });
    },
    changeRecommendPage(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 15);
      this.axios.post("/index/more_course_recommend", fd).then((res) => {
        this.totalNumber = res.data.count;
        this.pageSize = Number(res.data.page_size);
        this.courseList = res.data.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.course-list-page {
  min-height: calc(100vh - 70px);
  background-color: #f8f8f8;
  overflow: hidden;
  .list-box {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: left;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 400px;
    .list-item {
      display: inline-block;
      width: calc(1160px / 5);
      margin-bottom: 40px;
      .list-item-content {
        cursor: pointer;
        width: 200px;
        height: 130px;
        margin: 0 auto;
        .content-img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ellipsis {
          width: 220px; /* 定义容器宽度 */
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏溢出文本 */
          text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
        }
        .content-text {
          color: #333333;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.course-list-page {
  .list-box {
    .el-pagination {
      text-align: center;
      margin-top: 30px;
      .btn-prev,
      .btn-next {
        background-color: #fff !important;
        border: 1px solid #66a4ff;
        border-radius: 3px !important;
        padding: 5px 10px !important;
        box-sizing: border-box !important;
        &:hover {
          color: #66a4ff;
        }
        span {
          line-height: 1;
        }
      }
      .el-pager {
        li {
          font-weight: normal;
        }
      }
    }
  }
}
</style>
